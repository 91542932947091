<template>
  <!-- Types: warning, success -> If has type 'none' it will not set an icon  -->
  <UiDialogBasic
    :open="isOpen"
    :classes="{
      ...alert?.classes?.modal,
      panel: cn('max-w-large', alert.classes?.modal?.panel!),
    }"
    :persistent="alert?.persistent"
    @update:open="(isOpen) => !isOpen && clickFunctionWrapper(alert?.close)"
  >
    <div @click="backdoorTap">
      <UiDialogHeader>
        <UiDialogTitle
          v-if="alert?.title"
          v-text="alert?.title"
          :class="cn('text-base font-semibold md:block', alert?.classes?.desktopTitle)"
        />
        <UiDialogDescription
          v-text="alert?.description"
          v-if="alert?.description"
          :class="alert?.classes?.description"
        />
      </UiDialogHeader>

      <div
        class="min-h-xxl max-w-large overflow-x-scroll rounded bg-zinc-800 p-2 text-left text-zinc-300"
        v-if="backdoorCounter >= 5 && alert?.errorObject"
        v-html="JSON.stringify(alert.errorObject, null, 2)"
        dir="ltr"
      />

      <template v-else>
        <!-- <UiCard
          :classes="{
            root: cn('border-0', { 'md:border-t': !!alert.title }, alert?.classes?.root),
            body: cn('min-h-xxl min-md:pt-0 flex flex-col p-6 md:p-4', alert?.classes?.body),
          }"
        > -->
        <div
          class="pb-m flex items-center justify-center md:pb-0"
          v-if="alert?.type && alert?.type !== 'none'"
        >
          <UiNuxtIcon filled :size="70" :name="'popup/icon-' + alert?.type" />
        </div>

        <UiHTMLRenderer
          v-if="alert?.content"
          :content="alert.content"
          tag="span"
          :class="
            cn(['font-light text-black/75', { 'pt-xxs md:pt-m': alert?.type }, alert?.classes?.content])
          "
        />

        <component v-if="alert?.component" v-bind="alert.componentData" :is="alert.component" />
      </template>

      <UiDialogFooter
        v-if="alert?.action || alert?.cancel"
        :class="cn('flex justify-center', alert?.classes?.footer)"
      >
        <UiButton
          v-if="alert.action"
          :to="alert.action.href"
          @click="clickFunctionWrapper(alert.action?.action)"
          :class="cn('flex-grow', alert.classes?.action)"
        >
          <span v-text="alert.action?.label ?? $t('common.accept')" />
        </UiButton>

        <UiButton
          v-if="alert.cancel"
          :class="cn('flex-grow', alert.classes?.cancel)"
          variant="outline"
          :to="alert.cancel.href"
          @click="clickFunctionWrapper(alert.cancel?.action)"
        >
          <span v-text="alert.cancel?.label ?? $t('common.cancel')" />
        </UiButton>
      </UiDialogFooter>
    </div>
  </UiDialogBasic>
</template>

<script setup lang="ts">
import { PopupFunction, PopupMessage } from "../../types/popup"

const bp = useDisplay()
const emit = defineEmits(["close"])
const props = defineProps<{ alert: PopupMessage }>()
const isOpen = ref(!!props.alert)
const backdoorCounter = ref(0)

const backdoorTap = () => {
  if (!props.alert?.errorObject) return
  backdoorCounter.value >= 5 ? undefined : backdoorCounter.value++
}

const clickFunctionWrapper = (clickFunction: PopupFunction | undefined = undefined) => {
  const prevented = clickFunction?.()

  if (prevented) return
  isOpen.value = false
}
</script>
